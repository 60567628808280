
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Services from './pages/Services';
import AboutUs from './pages/AboutUs'
// import Navbar from './componant/Navbar'; 
import SubHeader from './componant/SubHeader'; // Ensure the path is correct
import Footer from './componant/Footer';
import GemGreen from './pages/GemGreen';
import SustainabilityPage from './pages/SustainabilityPage';
import GemCp from './pages/GemCp';
import Event from './pages/Event';
import Resources from './pages/Resources';
import Register from './pages/Register';
import NewNavbar from './componant/NewNavbar';
import GeoEcoSystem from './pages/GeoEcoSystem';
import CopyRight from './componant/CopyRight';
import GemGreenCerti from './CardPages/GemGreenCerti';
import GemProductCerti from './CardPages/GemProductCerti';
import CarbonFoot from './CardPages/CarbonFoot';
import SustainabilityAssessment from './CardPages/SustainabilityAssessment';
// import EcoSystem from './pages/EcoSystem';


export default function App() {
  return (
    <BrowserRouter>
      <SubHeader />
      {/* <Navbar /> */}
      < NewNavbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/services" element={<Services />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/gemGreen" element={<GemGreen />} />
        <Route path="/Sustainability" element={<SustainabilityPage />} />
        <Route path="/gemCp" element={<GemCp />} />
        <Route path="/ecoSystem" element={<GeoEcoSystem />} />
        <Route path="/events" element={<Event />} />
        <Route path="/register" element={<Register />} />
        <Route path="/ecoSystem" element={<Services />} />
        <Route path="/resources" element={<Resources />} />


        {/* Uncomment and add routes as needed */}
        {/* <Route path="/about" element={<About />} /> */}

        <Route path="/gem-green" element={<GemGreenCerti />} />
        <Route path="/gem-product" element={<GemProductCerti />} />
        <Route path="/carbon-footprint" element={<CarbonFoot />} />
        <Route path="/sustainability-assessment" element={<SustainabilityAssessment />} />
        {/* <Route path="/contact" element={<Contact />} /> */}
      </Routes>
      <Footer />
      <CopyRight />
    </BrowserRouter>
  );
}
