import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Sustainability.css';

// Your card data with links to routes
const cardData = [
    { imgSrc: 'cardImg/cardImg-1.png', text: 'GEM Green Building Certification', route: '/gem-green' },
    { imgSrc: 'cardImg/cardImg-2.png', text: 'GEM Eco-Product Certification', route: '/gem-product' },
    { imgSrc: 'cardImg/cardImg-3.png', text: 'Carbon Footprinting', route: '/carbon-footprint' },
    { imgSrc: 'cardImg/cardImg-4.png', text: 'Sustainability Assessment', route: '/sustainability-assessment' },
    { imgSrc: 'cardImg/cardImg-5.png', text: 'Criteria VII (Environmental parameters) of NAAC', route: '/criteria-vii' },
    { imgSrc: 'cardImg/cardImg-6.png', text: 'ISO 14001 conformity', route: '/iso-14001' },
    { imgSrc: 'cardImg/cardImg-7.png', text: 'Environmental Management Plans', route: '/environmental-management' },
    { imgSrc: 'cardImg/cardImg-8.png', text: 'Water Audits', route: '/water-audits' },
    { imgSrc: 'cardImg/cardImg-9.png', text: 'Energy Audits', route: '/energy-audits' },
];

const SustainabilityVer1 = () => {
    const [formData, setFormData] = useState({
        project: '',
        firstName: '',
        lastName: '',
        email: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        localStorage.setItem('formData', JSON.stringify(formData));
        alert('Form data saved to local storage!');
        console.log(formData);
    };

    return (
        <>
            <div className="headingDiv">
                <h2>Sustainability Services</h2>
            </div>
            <div className="sustainability-container">
                <div className="cards-container">
                    {cardData.map((card, index) => (
                        <Link to={card.route} key={index} className="card">
                            <div className="card-img">
                                <img src={require(`../assets/${card.imgSrc}`)} alt={`Sustainability Goal ${index + 1}`} />
                            </div>
                            <div className="card-text">
                                {card.text}
                            </div>
                        </Link>
                    ))}
                </div>
                <div className="form-container">
                    <h2>GEM Certification Enquiry</h2>
                    <form onSubmit={handleSubmit}>
                        <label>
                            Select Project:
                            <select name="project" value={formData.project} onChange={handleChange} required>
                                <option value="">Select a project</option>
                                <option value="project1">Project 1</option>
                                <option value="project2">Project 2</option>
                                <option value="project3">Project 3</option>
                            </select>
                        </label>
                        <label>
                            First Name:
                            <input
                                type="text"
                                name="firstName"
                                value={formData.firstName}
                                onChange={handleChange}
                                required
                            />
                        </label>
                        <label>
                            Last Name:
                            <input
                                type="text"
                                name="lastName"
                                value={formData.lastName}
                                onChange={handleChange}
                                required
                            />
                        </label>
                        <label>
                            Email:
                            <input
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                required
                            />
                        </label>
                        <button type="submit">Submit</button>
                        <h3>Certification fee provided here is tentative</h3>
                    </form>
                </div>
            </div>
        </>
    );
};

export default SustainabilityVer1;
