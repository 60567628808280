import React, { useState } from 'react';
import axios from 'axios'; // Import axios
import './Register1.css';
import { ToastContainer, toast } from 'react-toastify';  // Import ToastContainer and toast
import 'react-toastify/dist/ReactToastify.css';  // Import Toastify CSS

const countryStateData = {
  USA: ['Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California'],
  India: ['Maharashtra', 'Karnataka', 'Tamil Nadu', 'Delhi', 'Gujarat'],
  Canada: ['Ontario', 'Quebec', 'Nova Scotia', 'Manitoba', 'British Columbia'],
};

const countryCodes = {
  USA: '+1',
  India: '+91',
  Canada: '+1',
};

const Register = () => {
  const [selectedProject, setSelectedProject] = useState('');
  const [subProject, setSubProject] = useState('');
  const [salutation, setSalutation] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('');
  const [country, setCountry] = useState('');
  const [state, setState] = useState('');
  const [city, setCity] = useState('');
  const [pincode, setPincode] = useState('');
  const [mobile, setMobile] = useState('');
  const [telephone, setTelephone] = useState('');
  const [message, setMessage] = useState('');
  const [hasCertification, setHasCertification] = useState(false);
  const [certification, setCertification] = useState('');

  const projectOptions = {
    'Green building Certification': ['New Building', 'Existing Building', 'Factory Shed', 'Hospital', 'Educational Institute', 'Hotels', 'Banks', 'Others', 'Railway Stations', 'Airports', 'Ports and Harbours', 'Green Interiors'],
    'Gem Eco Product': ['1', '2', '3', '4', '5'],
    'carbon footprint': ['A', 'B', 'C', 'D'],
    'Sustainability Assessment': ['AB', 'AC', 'AD', 'AE', 'AG'],
  };

  const certificationOptions = ['ISO2001', 'ISO2002', 'ISO2003'];

  const handleProjectChange = (e) => setSelectedProject(e.target.value);

  const handleCountryChange = (e) => {
    setCountry(e.target.value);
    setState(''); // Reset state when country changes
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Create form data object
    const formData = {
      selectedProject,
      subProject,
      salutation,
      firstName,
      lastName,
      email,
      address,
      country,
      state,
      city,
      pincode,
      mobile,
      telephone,
      message,
      hasCertification,
      certification,
    };

    // Save form data to local storage
    localStorage.setItem('registerFormData', JSON.stringify(formData));

    try {
      // Send form data to the backend
      const response = await axios.post('http://localhost:9000/api/form/submit', formData);
      toast.success(response.data.message || 'Form submitted successfully!');
      handleCancel(); // Clear the form after successful submission
    } catch (error) {
      console.error('Error submitting form:', error);
      toast.error('Error submitting form. Please try again.');
    }
  };

  const handleCancel = () => {
    setSelectedProject('');
    setSubProject('');
    setSalutation('');
    setFirstName('');
    setLastName('');
    setEmail('');
    setAddress('');
    setCountry('');
    setState('');
    setCity('');
    setPincode('');
    setMobile('');
    setTelephone('');
    setMessage('');
    setHasCertification(false);
    setCertification('');
  };

  return (
    <div>
      <div className="Gem-main-div">
        <div className="GemButtonDiv">
          <h2>Registration</h2>
          <div className="Gem-btn">
            <a href="" className="Gem-btn-link">
              Home <span className="separator"> &gt; </span> Registration
            </a>
          </div>
        </div>
      </div>

      <div className='main-form-div'>
        {/* animation underline */}
        <div className="right-cont">
          <h2>Register For GEM</h2>
        </div>
        {/* animation umderline */}
        <div className="register-form">
          {/* <h1 className="form-heading">
              Register For GEM
              <span className="underline-animation"></span>
          </h1> */}

          <form onSubmit={handleSubmit}>
            {/* Select Project */}
            <div className="form-fields-data">
              <label>Select Your Project *</label>
              <select value={selectedProject} onChange={handleProjectChange} required>
                <option value="" disabled>Select a project</option>
                {Object.keys(projectOptions).map((project, index) => (
                  <option key={index} value={project}>{project}</option>
                ))}
              </select>
            </div>

            {/* Select Sub Project */}
            <div className="form-fields-data">
              <label>Select Your Sub Project *</label>
              <select value={subProject} onChange={(e) => setSubProject(e.target.value)} required>
                <option value="" disabled>Select a sub-project</option>
                {selectedProject ? (
                  projectOptions[selectedProject].map((option, index) => (
                    <option key={index} value={option}>{option}</option>
                  ))
                ) : (
                  <option value="" disabled>Select a project to see sub-projects</option>
                )}
              </select>
            </div>

            {/* Salutations */}
            <div className="form-fields-data flex-container">
              <label>Salutations *</label>
              <div className="radio-container">
                <div className="radio-item">
                  <input type="radio" name="salutation" value="Mr" onChange={(e) => setSalutation(e.target.value)} required />
                  <span>MR</span>
                </div>
                <div className="radio-item">
                  <input type="radio" name="salutation" value="Ms" onChange={(e) => setSalutation(e.target.value)} required />
                  <span>MS</span>
                </div>
                <div className="radio-item">
                  <input type="radio" name="salutation" value="Dr" onChange={(e) => setSalutation(e.target.value)} required />
                  <span>DR</span>
                </div>
              </div>
            </div>

            {/* First Name */}
            <div className="form-fields-data">
              <label>First Name *</label>
              <input type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)} required />
            </div>

            {/* Last Name */}
            <div className="form-fields-data">
              <label>Last Name *</label>
              <input type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} required />
            </div>

            {/* Email */}
            <div className="form-fields-data">
              <label>Email Id *</label>
              <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
            </div>

            {/* Address */}
            <div className="form-fields-data">
              <label>Address Line *</label>
              <input type="text" value={address} onChange={(e) => setAddress(e.target.value)} required />
            </div>

            {/* Country Selection */}
            <div className="form-fields-data">
              <label>Country *</label>
              <select value={country} onChange={handleCountryChange} required>
                <option value="" disabled>Select your country</option>
                {Object.keys(countryStateData).map((countryName, index) => (
                  <option key={index} value={countryName}>{countryName}</option>
                ))}
              </select>
            </div>

            {/* State Selection */}
            <div className="form-fields-data">
              <label>State *</label>
              <select value={state} onChange={(e) => setState(e.target.value)} required>
                <option value="" disabled>Select your state</option>
                {country ? (
                  countryStateData[country].map((stateName, index) => (
                    <option key={index} value={stateName}>{stateName}</option>
                  ))
                ) : (
                  <option value="" disabled>Select a country to see states</option>
                )}
              </select>
            </div>

            {/* City */}
            <div className="form-fields-data">
              <label>City *</label>
              <input type="text" value={city} onChange={(e) => setCity(e.target.value)} required />
            </div>

            {/* Pincode */}
            <div className="form-fields-data">
              <label>Pincode *</label>
              <input type="text" value={pincode} onChange={(e) => setPincode(e.target.value)} required />
            </div>

            {/* Mobile with Country Code */}
            <div className="form-fields-data flex-container">
              <label>Mobile No. (With Country Code) *</label>
              <div className="flex-container">
                <select className='country-code' value={countryCodes[country]} disabled>
                  {country && <option>{countryCodes[country]}</option>}
                </select>
                <input className='phone' type="tel" value={mobile} onChange={(e) => setMobile(e.target.value)} required /> 
                
              </div>
            </div>

            {/* Telephone */}
            <div className="form-fields-data">
              <label>Telephone No. (With STD Code)</label>
              <input type="tel" value={telephone} onChange={(e) => setTelephone(e.target.value)} />
            </div>

            {/* Message */}
            <div className="form-fields-data">
              <label>Message</label>
              <textarea value={message} onChange={(e) => setMessage(e.target.value)} />
            </div>

            {/* Certification Question */}
            <div className="form-fields-data flex-container">
              <label>Have you undertaken any other certification? *</label>
              <div className="checkbox-container">
                <div className="checkbox-item">
                  <input type="checkbox" checked={hasCertification} onChange={() => setHasCertification(!hasCertification)} />
                  <span>Yes</span>
                </div>
                <div className="checkbox-item">
                  <input type="checkbox" checked={!hasCertification} onChange={() => setHasCertification(!hasCertification)} />
                  <span>No</span>
                </div>
              </div>
            </div>

            {/* Conditional Certification Dropdown */}
            {hasCertification && (
              <div className="form-fields-data flex-container">
                <label>Select Certification</label>
                <select value={certification} onChange={(e) => setCertification(e.target.value)} required>
                  <option value="" disabled>Select certification</option>
                  {certificationOptions.map((option, index) => (
                    <option key={index} value={option}>{option}</option>
                  ))}
                </select>
              </div>
            )}

            {/* Buttons */}
            <div className="button-group">
              <button type="submit" className="submit-btn">Submit</button>
              <button type="button" className="cancel-btn" onClick={handleCancel}>Cancel</button>
            </div>

          </form>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Register;
