import React from 'react'
import './GemGreenCerti.css'

const GemProductCerti = () => {
  return (
    <div>
    <div className="GEMCP-main-div">
      <div className="GEMCPButtonDiv">
        <h2>GEM Eco-Product Certification</h2>
        <div className="GEMCP-btn">
          <a href="" className="GEMCP-btn-link">
            Home <span className="separator"> &gt; </span> GEM Eco-Product Certification
          </a>
        </div>
       
      </div>
    </div>
    <div className="GEMCP-flex-div">
    <div className="important-link">
          <a href="">Important Links</a>
        </div>
        <div className="count-div">
            <h2></h2>
        </div>
        </div>
        <div className="text-container">

            <h2>GEM Eco-Product Certification</h2>
            </div>
            <div className="text-para-div">
            <p>
            The GEM Eco-product certification offers product manufacturers a unique preposition for assessing their products being environmentally benign. The eco-product certification considers several factors such as the Life cycle assessment, energy savings, waste management and water conservation as benchmark for certifying a product as green or eco-friendly. In addition, to the above, the products are evaluated for their cost effectiveness, user friendliness and their resolve in aligning with global goals such as the SDGs.
            </p>

            <p>
            The ISO 14020 defines environmental labelling as:
            Environmental labels and declarations provide information about a product or service in terms of its overall environmental character, a specific environmental aspect, or any number of aspects.

            </p>

            <p>
            The GEM eco-product certification goes beyond being just an environmental label. The certificate is a recognition for the products which have been evaluated based on sustainability principles of environmental, social and economic considerations. The GEM Eco-product certification values the efforts of companies in ensuring that a product undergoes thorough evaluation for its impacts during the life cycle.
            </p>

            <p>
            The certification considers a life cycle approach for evaluating products as eco-labelling. 
            </p>

            <p>
            The ASSOCHAM GEM Eco-product certification is intended to assist companies and organizations for enabling them to be conscious of their processes and management towards sustainability through changes which are aligned towards LiFE (Lifestyle for Environment) and circular economy concepts. 
            </p>
        </div>
        
  </div>
  )
}

export default GemProductCerti
