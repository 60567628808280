import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faXmark } from '@fortawesome/free-solid-svg-icons';
import logo1 from '../assets/logo-img2.png';
import logo2 from '../assets/logo-img1.png';
import './NewNavbar.css';

const NewNavbar = () => {
    const [menuOpen, setMenuOpen] = useState(false);

    const handleMenuClose = () => {
        setMenuOpen(false);
    };

    return (
        <nav className="navbar">
            <div className="logo-container">
                <img src={logo1} alt="Logo 1" className="logo" />
                <img src={logo2} alt="Logo 2" className="logo" />
            </div>

            <div
                className={`menu-icon ${menuOpen ? 'rotate' : ''}`}
                onClick={() => setMenuOpen(!menuOpen)}
            >
                <FontAwesomeIcon icon={menuOpen ? faXmark : faBars} />
            </div>

            <ul className={`nav-links ${menuOpen ? 'open' : ''}`}>
                <li><Link to="/">Home</Link></li>
                <li><Link to="/about">About Us</Link></li>
                <li><Link to="/gemGreen">GEM Green Certification</Link></li>
                <li><Link to="/Sustainability">Sustainability Solutions</Link></li>
                <li className="dropdown">
                    <Link to="/ecoSystem">GEM Ecosystem</Link>
                    <ul className="dropdown-menu">
                        <li><Link to="/chapters">GEM Chapters</Link></li>
                        <li><Link to="/eco-products">GEM Eco-Products</Link></li>
                        <li><Link to="/green-building-certification">GEM Green Building Certification</Link></li>
                        <li><Link to="/cps">GEM CPs</Link></li>
                    </ul>
                </li>
                <li><Link to="/gemCp">Certified Professionals</Link></li>
                <li><Link to="/events">Events</Link></li>
                <li><Link to="/register">Register For Projects</Link></li>
                <li><Link to="/resources">Resources</Link></li>
            </ul>
        </nav>
    );
};

export default NewNavbar;
