import React from 'react'
import '../CardPages/GemGreenCerti.css'
import '../CardPages/SustainabilityAssessment.css'

const SustainabilityAssessment = () => {
  return (
    <div>
    <div className="GEMCP-main-div">
      <div className="GEMCPButtonDiv">
        <h2>Sustainability Assessment Programme (SAP)</h2>
        <div className="GEMCP-btn">
          <a href="" className="GEMCP-btn-link">
            Home <span className="separator"> &gt; </span> Sustainability Assessment Programme (SAP)
          </a>
        </div>
       
      </div>
    </div>
    <div className="GEMCP-flex-div">
    <div className="important-link">
          <a href="">Important Links</a>
        </div>
        <div className="count-div">
            <h2></h2>
        </div>
        </div>
        <div className="text-container">

            <h2>Sustainability Assessment Programme (SAP)</h2>
            </div>
            <div className="text-para-div">
            <p>
            The SAP is a holistic 360-degree assessment of an organisation’s intention and ability to undertake suitable evaluation in terms of sustainability pillars.
            </p>

            <h3>Objectives of SAP</h3>
            <ul className="sap-objective">
                <li>
                Evaluate the environmental impacts of process
                </li>
                <li>
                Undertake a socio-economic impact assessment
                </li>
                <li>
                Provide a green product certification
                </li>
                <li>
                Provide a carbon footprinting certification
                </li>
                <li>
                 Integrate the above objectives for producing a sustainability assessment report
                </li>
            </ul>
            <h3>Scope of Work of SAP</h3>
            <h4>The scope of work include:</h4>
            <ul className="sap-objective">
                <li>GHG based Life Cycle Assessment</li>
                <li>Carbon Footprinting</li>
                <li>Green Product certification</li>
                <li>Socio-economic impact assessment</li>
            </ul>

            <h3>Environmental Impact assessment (EIA) using Life Cycle Assessment (LCA)</h3>

            <p>
            The philosophy of this project is life cycle thinking during a project’s execution. Based on the functional unit, we propose undertaking an Environmental Assessment through a Life cycle assessment as per ISO: 14040 and ISO: 14044, with possibilities of different boundary conditions such as gate to gate, cradle to gate and cradle to grave.  The LCA would be carried out using the GHG emissions methodology or a midpoint/ endpoint analysis utilising acceptable methodologies. A water footprint will also be considered for the entire scope.

            </p>

          
        </div>
        
  </div>
  )
}

export default SustainabilityAssessment
