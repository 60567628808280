import React from 'react'
import '../CardPages/GemGreenCerti.css'

const CarbonFoot = () => {
  return (
    <div>
    <div className="GEMCP-main-div">
      <div className="GEMCPButtonDiv">
        <h2>Carbon Foot printing (CFP)</h2>
        <div className="GEMCP-btn">
          <a href="" className="GEMCP-btn-link">
            Home <span className="separator"> &gt; </span> Carbon Foot printing (CFP)
          </a>
        </div>
       
      </div>
    </div>
    <div className="GEMCP-flex-div">
    <div className="important-link">
          <a href="">Important Links</a>
        </div>
        <div className="count-div">
            <h2></h2>
        </div>
        </div>
        <div className="text-container">

            <h2>Carbon Foot printing (CFP)</h2>
            </div>
            <div className="text-para-div">
            <p>
            CFP is a unique attempt at formulating a new-age carbon footprinting guidebook for solutions and product segments, including manufacturing, IT and ITES, to enable them to understand and assess their journey towards net neutrality. The guidebook is based on an easy-to-use, qualitative assessment inspired by the ISO and UNFCCC guidelines on carbon footprint. This is intended to catalyze a company’s ambition to be climate-friendly, optimize natural resource consumption, reduce waste, and enhance profitability in the long term.
            </p>

            <p>
            The objective of the carbon footprint assessment programme is to raise the environmental responsibility quotient of any organisation which intends to contribute towards a corporate image in line with the national climate change commitments as well as business goals of a resilient and sustainable future. 
            </p>

            <p>
            This CFP guide would also be a positive step in acknowledging, recognising and qualitatively assessing carbon emissions and ensuring that the country’s goal of net neutrality by 2070 is met through corporate leadership.
            This CFP guide provides a comprehensive yet simple manner for estimation of carbon and potential of carbon reduction. The guide also includes references from LiFE initiatives and UNFCCC carbon neutral initiative which promotes the concepts of Measure, Reduce and Contribute.

            </p>

            <p>
            This CFP guide would also be a positive step in acknowledging, recognising and qualitatively assessing carbon emissions and ensuring that the country’s goal of net neutrality by 2070 is met through corporate leadership.
            This CFP guide provides a comprehensive yet simple manner for estimation of carbon and potential of carbon reduction. The guide also includes references from LiFE initiatives and UNFCCC carbon neutral initiative which promotes the concepts of Measure, Reduce and Contribute.

            </p>

            <p>
            There are several benefits of the CFP including demonstration of the intent and leadership in achieving carbon neutrality. The assessment can help in developing a climate action plan for your businesses and activities. It is based on the philosophy of any establishment’s resolve and contribution towards national LiFE initiative. It would also be beneficial for showcasing commitments and action towards building a sustainable future and reducing environmental footprint. The guide should help companies including micro, small and medium enterprises to recognize improvement areas in resource conservation. This guide also helps in achieving different levels of reduction plan such as Gold, Silver, or Bronze.
            </p>
        </div>
        
  </div>
  )
}

export default CarbonFoot
