import React, { useState } from "react";
import India from "@svg-maps/india"; // Make sure you have this installed
import { SVGMap } from "react-svg-map";
import "react-svg-map/lib/index.css";
import './GeoEcoSystem.css';

function GeoEcoSystem() {
  const [hoveredState, setHoveredState] = useState("");
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  const onLocationHover = (event) => {
    const stateName = event.target.getAttribute("name");
    setHoveredState(stateName);

    const rect = event.target.getBoundingClientRect();
    setMousePosition({
      x: rect.left + rect.width / 2, // Center the info box horizontally
      y: rect.top + rect.height / 2,  // Center the info box vertically
    });
  };

  const onLocationLeave = () => {
    setHoveredState("");
  };

  return (
    <div>
        <div className="main-div-of-map">
        <div className="map-main-div">
            <div className="btn-div-map">
                <ul>
                    <li>
                        <a href="">Chairman</a>
                    </li>
                    <li>
                        <a href="">Co-Chairman</a>
                    </li>
                    <li>
                        <a href="">State</a>
                    </li>
                </ul>
            </div>
    <div className="map-container">
      <SVGMap
        map={India}
        onLocationMouseOver={onLocationHover}
        onLocationMouseOut={onLocationLeave}
      />
      {hoveredState && (
        <div className="hover-info" style={{ left: mousePosition.x, top: mousePosition.y }}>
          {hoveredState}
        </div>
      )}
    </div>
    </div>
    </div>
    </div>
  );
}

export default GeoEcoSystem;
