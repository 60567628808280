import React from 'react'
import './GemCp.css';

const GemCp = () => {
  return (
    <div>
    <div className="GEMCP-main-div">
      <div className="GEMCPButtonDiv">
        <h2>GEM CP</h2>
        <div className="GEMCP-btn">
          <a href="" className="GEMCP-btn-link">
            Home <span className="separator"> &gt; </span> GEM CP
          </a>
        </div>
       
      </div>
    </div>
    <div className="GEMCP-flex-div">
    <div className="important-link">
          <a href="">Important Links</a>
        </div>
        <div className="count-div">
            <h2></h2>
        </div>
        </div>
        
  </div>
  )
}

export default GemCp
