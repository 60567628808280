import React from 'react'
import './GemGreen.css';

const GemGreen = () => {
  return (
    
       <div>
      <div className="Gem-main-div">
        <div className="GemButtonDiv">
          <h2>GEM Certification System</h2>
          <div className="Gem-btn">
            <a href="" className="Gem-btn-link">
              Home <span className="separator"> &gt; </span> GEM Certification System
            </a>
          </div>
         
        </div>
      </div>
      <div className="GEMCP-flex-div">
    <div className="important-link">
          <a href="">Important Links</a>
        </div>
        <div className="count-div">
            <h2></h2>
        </div>
        </div>
          
    </div>
    
  )
}

export default GemGreen
