import React from 'react'
import './GemGreenCerti.css'

const GemGreenCerti = () => {
  return (
    <div>
    <div className="GEMCP-main-div">
      <div className="GEMCPButtonDiv">
        <h2>GEM Green building certification</h2>
        <div className="GEMCP-btn">
          <a href="" className="GEMCP-btn-link">
            Home <span className="separator"> &gt; </span> GEM Green building certification
          </a>
        </div>
       
      </div>
    </div>
    <div className="GEMCP-flex-div">
    <div className="important-link">
          <a href="">Important Links</a>
        </div>
        <div className="count-div">
            <h2></h2>
        </div>
        </div>
        <div className="text-container">

            <h2>GEM Green building certification</h2>
            </div>
            <div className="text-para-div">
            <p>
            The GEM green building certification is an indigenous systematic evidence-based rating system for built environment including commercial and residential complexes. It is designed on the principles of NBC 2016, ECBC 2017 and selected CPWD guidelines. The objective is to ensure future buildings move towards environment friendly methods of design, construction, and operations. The GEM certification programme has so far successfully rated many projects across the country. The projects range from residential to high rise, mixed land use, interior, institutional, commercial, factory, hotel, hospital as well as public transportation including airports and railway stations.
            </p>

            <p>
            GEM has completed more than 200 projects and is supported by an ecosystem of more than 2000 architects, MEP consultants, GEM CP, sectoral experts and others across the country. The GEM green building certification offers Pre-certification (for green field/design phase or under construction) and final certification for (new and existing buildings). It offers a unique preposition for enabling tangible and indirect savings throughout the life cycle of the projects with respect to energy, materials, HVAC costs and many more aspects.
            </p>
        </div>
        
  </div>
  )
}

export default GemGreenCerti
