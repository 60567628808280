import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faSquareInstagram, faTwitter, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import './subheader.css';
import './Navbar.css';


const SubHeader = () => {
  return (
    <div className="subheader">
       <div className="headingContent">
       <marquee>Welcome to ASSOCHAM Sustainability Initiatives</marquee>
       </div>
       <div className="subHeaderNav">
      <ul>
        <li>
          <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" aria-label="Facebook">
            <FontAwesomeIcon icon={faFacebookF} />
          </a>
        </li>
        <li>
        <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" aria-label="Instagram">
        <FontAwesomeIcon icon={faSquareInstagram} />
          </a>
        </li>

        <li>
        <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" aria-label="Twitter">
           <FontAwesomeIcon icon={faTwitter} />
          </a>
        </li>

        <li>
        <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn">
        <FontAwesomeIcon icon={faLinkedin} />
          </a>
        </li>
        <button className='btn'>
            <a href="">Contact Us</a>
        </button>
        {/* Add more icons here as needed */}
      </ul>
    </div>
    </div>
  );
};

export default SubHeader;
